.ant-layout {
  background-color: white !important;
}
.dashboard-layout {
  color: white;
  overflow: hidden;
}

.dashboard-layout .dashboard-sidebar {
  background-color: #022b3a !important;
  font-family: montserrat, sans-serif;
  background: #022b3a;
}

.dashboard-layout .dashboard-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-layout .dashboard-main .dashboard-header {
  background: #fab700;
  height: 250px;
}

.dashboard-wrapper {
  background-color: #f9fafc !important;
}
.dashboard-layout .dashboard-main .dashboard-main-content {
  overflow: auto;
  background-color: white;
  width: 90%;
  margin: -30px auto 40px auto !important;
  border-radius: 10px;
  background: transparent;
  /* box-shadow: 0px 3px 10px #0000001A; */
}

.dashboard-layout .dashboard-main .dashboard-main-content.sidebar-closed {
  width: calc(100vw - 80px);
}
/*# sourceMappingURL=styles.css.map */

@media (max-width: 500px) {
  .dashboard-layout .dashboard-main .dashboard-header {
    height: 160px;
  }
  .dashboard-layout .dashboard-main .dashboard-main-content {
    overflow: auto;
    background-color: white;
    width: 90%;
    margin: -30px auto 30px auto !important;
    border-radius: 10px;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    box-shadow: 0px 3px 10px #0000001a;
  }
}
