.message-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.contacts-table {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.contact-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
  object-fit: cover !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.w-500 {
  font-weight: 500 !important;
}
.flex-row-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.row-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.row-flex-center {
  display: flex !important;
  justify-content: center !important;
}
.flex-space-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: "center" !important;
}
.row-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-col {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.sub-admin-input {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.cursor {
  cursor: pointer !important;
}
.contact-status {
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.email-col {
  background: #fab70040 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 10px;
  height: 35px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.email-div {
  width: 380px;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.reply-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 80px;
  overflow-y: auto;
}
.reply-col {
  background: #f9fafc;
  border-radius: 5px;
  padding: 10px;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
