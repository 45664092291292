.mt-20 {
  margin-top: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.ml-0 {
  margin: 0 !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mx-20 {
  margin-inline: 20px !important;
}
.my-20 {
  margin-block: 20px !important;
}
.dashed-line {
  align-self: center !important;
  height: 1px !important;
  width: 95% !important;
  border: 1px dashed #9da3a5 !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.flex-row-end {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}
.flex-row-space {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.flex-row-start {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.flex-row-align-start {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
}
.flex-row-align-start-not-space {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}
.report-profile {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 20px !important;
  border: 1px solid #9da3a5 !important;
  /* margin-right: 30px !important; */
  margin-bottom: 20px !important;
}
.profile-report-div-reporter-img {
  /* width: 40px !important; */
  /* height: 40px !important; */
  border-radius: 50px !important;
}
.icon-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  padding: 10px 0px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}
.ignore-btn {
  background-color: #fab700 !important;
}
.ban-btn {
  background-color: #000000 !important;
}
.suspend-btn {
  background-color: #fe6779 !important;
}
.comment-btn {
  background-color: #5596f8 !important;
}
.comment-btn-disabled {
  background-color: #5596f8bd !important;
  cursor: not-allowed !important;
}
.btn-icon-img {
  width: 15px !important;
  margin-right: 3px !important;
}
.post-poll {
  border: 1px solid #b1bbc9 !important;
  border-radius: 8px !important;
  padding: 5px 10px !important;
  margin-top: 10px !important;
}
.post-tags {
  background-color: #fab70040 !important;
  border-radius: 5px !important;
  padding: 5px !important;
  width: max-content !important;
  margin-right: 10px !important;
}
.pointer {
  cursor: pointer !important;
}
.three-dot {
  display: block;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
