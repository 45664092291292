.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-row-space-between {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.text-row-div {
  padding-block: 25px;
}
.row-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0px 3px 10px #0000001a;
  padding: 25px;
}
.chart-div-grid {
  padding: 20px !important;
  box-shadow: 0px 3px 10px #0000001a !important;
  border-radius: 10px !important;
  margin-top: 20px;
  background-color: white;
}
.line {
  background-color: #d9dcea;
  width: 1px;
  height: 350px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
