body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.contact-type-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ffffff !important;
  width: 380px !important;
  color: #8e8e8e !important;
  border-radius: 10px !important;
  border: 1px solid #f5f5f5 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  font-family: "Poppins" !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f1f6fa !important;
  min-width: 160px;
  color: #71828a !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}

.promo-code-select-field.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ffffff !important;
  border: none !important;
  border-bottom: 1px solid #f5f5f5 !important;
  border-radius: 10px !important;
  align-items: center !important;
  color: #71828a !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;

}

.ant-select-multiple .ant-select-selection-item {
  background: #fab700 !important;
  color: white !important;
  border-radius: 7px !important;
}

.ant-table-thead>tr>th {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  background-color: #f1f6fa;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  height: 0 !important;
}

.ant-switch-checked {
  background-color: #00b517 !important;
}

.ant-modal-close-x {
  width: 45px !important;
  height: 45px !important;
  font-size: 16px !important;
  margin-top: -11px !important;
  margin-right: -10px !important;
  line-height: 47px !important;
  background-color: black !important;
  border-radius: 50px !important;
  color: white !important;
}

.ant-modal .ant-modal-content {
  border-radius: 10px !important;
  width: 450px !important;
}

.ant-modal .ant-modal-body,
.modal-lg .ant-modal-body {
  padding: 34px !important;
}

.confirmation-modal .ant-modal-body {
  padding: 0 !important;
}

.confirmation-modal .ant-modal-content {
  border-radius: 20px !important;
}

.promo-modal .ant-modal-content {
  border-radius: 10px !important;
  width: 700px !important;
  min-height: 460px !important;
}

.ant-modal .ant-modal-body .promo-modal .ant-modal-body {
  padding: 40px !important;
}

.ant-layout-header {
  line-height: 45px !important;
}

.modal-lg .ant-modal-content {
  border-radius: 10px !important;
  width: 650px !important;
}

.ant-modal-centered .ant-modal {
  margin-right: 60px !important;
}

@media (max-width: 500px) {
  .ant-layout-header {
    padding: 0 25px !important;
  }
}

/* .ant-pagination-item-active{
  background-color: #F1F6FA !important;
  border: none !important;
}
.ant-pagination-item-active a{
  color: #272727 !important;
}
.ant-pagination-item{
  border: none !important;
  border: 2px solid #6F96AA !important;
}
.ant-pagination-item a{
  color: #6F96AA !important;
} */
.ant-tabs-tab {
  font-family: "Poppins" !important;
  color: #8e8e8e !important;
  font-size: 16px !important;
}

.ant-tabs-ink-bar {
  background: #fab700 !important;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #14172e !important;
}

.ant-tabs {
  font-family: "Poppins" !important;
  color: #14172e !important;
  font-size: 16px !important;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  background-color: #f1f6fa !important;
  padding-inline: 20px !important;
}

.pie-chart-div .recharts-legend-wrapper {
  width: max-content !important;
  height: auto !important;
  top: 100px !important;
  left: 250px !important;
}

.pie-chart-div .recharts-default-legend {
  text-align: left !important;
  display: flex;
  flex-direction: column;
  width: 215px;
  position: absolute;
  bottom: 170px;
  right: -10px;
  min-height: 100px;
  overflow-y: auto;
  padding: 10px;
}

.recharts-legend-item-text {
  display: inline-block;
  white-space: nowrap;
  width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ant-picker {
  background-color: #f1f6fa !important;
  border-radius: 5px !important;
  height: 40px !important;
  border: none !important;
  width: 250px !important;
}

.recharts-default-legend .recharts-legend-item {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  color: #fab700 !important;
}

.ant-picker-year-btn {
  color: #71828a !important;
}

.ant-select-selection {
  background-color: green !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #fab700 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: transparent !important;
}

textarea.ant-input {
  background-color: #ffffff !important;
  width: 380px !important;
  color: gray !important;
  border-radius: 10px !important;
  border: 1px solid #f5f5f5 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  font-family: "Poppins" !important;
}

.modal-textarea textarea.ant-input {
  background-color: #ffffff !important;
  width: 100% !important;
  color: gray !important;
  border-radius: 10px !important;
  border: 2px solid #6f96aa !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  background-color: #ffffff !important;
}

.autocomplete-field .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  color: #8e8e8e !important;
  border: 1px solid #f5f5f5 !important;
  padding: 20px 11px !important;
}

.promo-screen-range-picker {
  width: 100% !important;
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  color: #8e8e8e !important;
  border: 1px solid #f5f5f5 !important;
  padding: 20px 11px !important;
}

.promo-screen-range-picker .ant-picker-range-separator{
  margin-right: 25px !important;
}

.ant-picker-input > input:placeholder-shown{
  font-size: 16px;
  font-weight: 400 !important;
  font-family: "Poppins" !important; 
}


.anticon-rotate-left {
  display: none !important;
}
.anticon-rotate-right {
  display: none !important;
}
.anticon-zoom-out {
  display: none !important;
}
.anticon-zoom-in{
  display: none !important;
}
.ant-image-preview-operations {
  background: none !important;
}