.add-admin-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.sub-admin-table {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.sub-admin-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
  object-fit: cover !important;
}
.sub-admin-add-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.flex-row-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.row-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.row-flex-center {
  display: flex !important;
  justify-content: center !important;
}
.row-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-col {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.flex-row{
  display: flex !important;
  flex-direction: row !important;
}
.sub-admin-input {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.cursor {
  cursor: pointer !important;
}
.user-password {
  font-size: 14px !important;
  font-family: "Poppins" !important;
  color: #33323b !important;
  font-weight: 600 !important;
}
.avatar-box {
  position: relative !important;
}
.avatar-img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.avatar-file-upload {
  display: none !important;
}
.avatar-camera {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  cursor: pointer !important;
}
