.stars span {
  font-size: 17px !important;
}
.service-provider-img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.service-provider-status {
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  width: 100px;
  border-radius: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-provider-msg-label {
  text-align: left;
  color: "#05031A";
  font-family: "Poppins";
  font-size: 16px;
}
.service-provider-msg-input {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 10px;
  border: none !important;
  outline: none !important;
}
.service-provider-email-btn {
  background-color: #fab700 !important;
  color: white !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100% !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.service-provider-email-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.service-provider-table {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-row-start {
  display: flex !important;
  justify-content: flex-start !important;
}
.flex-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.user-search-field {
  width: 100% !important;
  border: none !important;
  font-size: 14px !important;
  background-color: #f1f6fa !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease 0s !important;
  border-radius: 5px !important;
}
.user-table {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  cursor: pointer !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.user-status {
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  /* font-size: 12px !important;
  width: 80px !important; */
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.user-subject-field {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.my-20 {
  margin-block: 20px !important;
}
.text-label {
  text-align: left !important;
  color: #05031a !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
}
.user-msg-btn {
  background-color: #fab700 !important;
  color: white !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100% !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.service-user-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
}
.service-user-email-img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}

.service-user-business-prof {
  border: 1px solid rgba(174, 174, 174, 0.54) !important;
  padding: 1rem !important;
  border-radius: 10px !important;

}

.services-img{
  width: 100% !important;
  /* height: 70% !important; */
  border-radius: 5px !important;
  object-position: center !important;
  object-fit: cover !important;
}

.ant-table{
  font-size: 13px !important;
}

