.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-row-start {
  display: flex !important;
  justify-content: flex-start !important;
}
.flex-center {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.user-search-field {
  width: 100% !important;
  border: none !important;
  font-size: 14px !important;
  background-color: #f1f6fa !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease 0s !important;
  border-radius: 5px !important;
}
.user-table {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.user-status {
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.user-subject-field {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.my-20 {
  margin-block: 20px !important;
}
.text-label {
  text-align: left !important;
  color: #05031a !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
}
.user-msg-btn {
  background-color: #fab700 !important;
  color: white !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100% !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.service-user-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
}
.service-user-email-img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}

.table-button {
  width: 100%;
  height: 40px;
  border-radius: 10px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 16px;
  font-family: "Poppins" !important;
}

.ant-image-mask {
  display: none !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
  background-color: #fab700 !important;
}
