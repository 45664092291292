.nav-header-container {
  padding-top: 40px;
}
.nav-header-container .upper-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins" !important;
}
.nav-header-container .lower-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Poppins" !important;
  margin-top: 30px;
}
.nav-header-container .lower-navbar ul{
  display: -webkit-box;
  display: -ms-flexbox;
  width: 1035px;
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
.mobile-navbar ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.nav-header-container .lower-navbar ul li,
.mobile-navbar ul li {
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins" !important;
  color: white;
  cursor: pointer;
}
.nav-header-container .lower-navbar ul li{
  color: white;
  /* padding-inline: 15px; */
}
.mobile-navbar ul li{
  color: black;
  padding-block: 10px;
  padding-left: 15px;
  margin-block: 5px;
}
.nav-header-container .lower-navbar ul li.selected,
.mobile-navbar ul li.selected {
  background: #fbc32b;
  cursor: pointer;
  border-radius: 5px;
  font-family: Poppins;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  color: #ffffff;
}
.nav-header-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.nav-header-container .trigger {
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.nav-header-container .trigger.bg-purple {
  font-size: 20px !important;
  color: white !important;
  padding: 8px;
  border-radius: 50% !important;
  background-color: #022b3a;
}

.nav-header-container .trigger:hover {
  color: #022b3ab7;
}

.nav-header-container .dashboard-title {
  font-weight: 600;
  display: inline-block !important;
  padding-left: 1.2rem;
  height: 37px;
  color: #4d4f5c;
  width: 172px;
  margin-left: 10px;
  text-transform: capitalize;
}

.nav-header-container .site-layout-background-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav-header-container .site-layout-background-right div {
  padding: 0 10px;
  font-size: 1.7rem;
}

.nav-header-container .site-layout-background-right .profile {
  display: flex;
  /* width: 500px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
}

.nav-header-container .site-layout-background-right .headerIcons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.nav-header-container .site-layout-background-right .headerIcons img {
  width: 26px;
  height: 31px;
}

.nav-header-container .site-layout-background-right .profile > img {
  width: 47px;
  height: 47px;
  border-radius: 50px;
  object-fit: cover;
}

.dropDownContainer {
  color: #4d4f5c;
  font-family: "Montserrat", sans-serif;
  -webkit-box-shadow: 0 0 40px #0000001f;
  box-shadow: 0 0 40px #0000001f;
  font-family: 17px;
  border-radius: 8px !important;
  width: 250px;
  height: max-content;
}

.dropDownContainer .dropDownList {
  border-radius: 8px !important;
  padding: 10px 0;
}

.dropDownContainer .dropDownList .dropDownItem {
  padding: 0 25px;
  margin: 0;
}

.dropDownContainer .lastChild {
  white-space: normal !important;
  text-overflow: initial !important;
  overflow: initial !important;
  line-height: 2 !important;
  height: 64px !important;
}
.mx-10{
  margin-inline: 10px !important;
}
.mr-10{
  margin-right: 10px !important;
}
.ant-dropdown {
  top: 65px !important;
}
/*# sourceMappingURL=styles.css.map */
@media (max-width: 1250px) {
  .nav-header-container .lower-navbar ul li {
    padding-inline: 10px;
    font-weight: 400;
    font-size: 15px;
  }
}
@media (max-width: 1032px) {
  .nav-header-container .lower-navbar ul li {
    padding-inline: 8px;
    font-weight: 400;
    font-size: 14px;
  }
}
@media (max-width: 970px){
  .nav-header-container .lower-navbar ul{
    display: none;
  }
  .nav-header-mobile {
    display: flex;
  }
}
@media (max-width: 768px){
  .nav-header-container .site-layout-background-right .profile {
    font-size: 1.15rem;
    /* width: 190px; */
  }
}
@media (max-width: 500px){
  .nav-header-container .upper-navbar img{ 
    width: 95%;
  }
  .mobile-navbar ul li{
    text-align: right;
    padding-right: 15px;
  }
}
@media (max-width: 500px){
  .nav-header-container .upper-navbar img{ 
    width: 95%;
  }
  .mobile-navbar ul li{
    text-align: right;
    padding-right: 15px;
  }
  .nav-header-container .site-layout-background-right .profile {
    width: 50px;
    justify-content: flex-end;
  }
  .nav-header-container .upper-navbar img{ 
    width: 200px;
  }
}