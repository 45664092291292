.add-promo-btn {
    background-color: #fab700 !important;
    color: #fff !important;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    font-size: 16px !important;
    width: 100% !important;
    padding-inline: 15px !important;
    border-radius: 10px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: capitalize !important;
}

.user-subject-field {
    border: 1px solid #f5f5f5 !important;
    box-shadow: 0px 3px 6px #0000001a !important;
    border-radius: 10px !important;
}

.ant-picker .ant-see {
    background-color: #ffffff !important;
    color: gray !important;
    border: none !important;
    border-radius: 0 !important;
  }
  .mt-40{
    margin-top: 40px !important;
  }