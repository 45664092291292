.service-details-back-btn {
  color: #000;
  font-size: 34px;
  cursor: pointer;
}
.sub-service-add-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.service-details-table {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.sub-service-add-btn-modal {
  background-color: transparent !important;
  color: black !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 100% !important;
  letter-spacing: 0px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.row-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.row-flex-center {
  display: flex !important;
  justify-content: center !important;
}
.row-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.service-input {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.cursor {
  cursor: pointer !important;
}
.add-admin-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.sub-admin-table {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.sub-admin-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
  object-fit: cover !important;
}
.sub-admin-add-btn {
  background-color: #fab700 !important;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
  width: 100% !important;
  padding-inline: 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.flex-row-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.row-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.row-flex-center {
  display: flex !important;
  justify-content: center !important;
}
.row-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-col {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.sub-admin-input {
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border-radius: 10px !important;
}
.cursor {
  cursor: pointer !important;
}
.user-password {
  font-size: 14px !important;
  font-family: "Poppins" !important;
  color: #33323b !important;
  font-weight: 600 !important;
}
.avatar-box {
  position: relative !important;
}
.avatar-img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.avatar-file-upload {
  display: none !important;
}
.avatar-camera {
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  cursor: pointer !important;
}
.drag-area-div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  border: 1px dashed #0658fd99 !important;
  border-radius: 5px !important;
  color: #0658fd99 !important;
  background-color: #0658fd0d !important;
  padding: 20px 75px !important;
}
.drag-area-text-heading {
  margin: 0 !important;
  margin-top: 20px !important;
}
.drag-area-row {
  margin: 10px 0 20px 0 !important;
}
.add-more-btn {
  background-color: transparent !important;
  color: black !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  width: 100% !important;
  letter-spacing: 0px !important;
  border-radius: 10px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: capitalize !important;
}
.my-10-20 {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
